import {
  Button,
  TextField,
  Box,
  CardHeader,
  Card,
  Stack,
  CardActions,
  Typography,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { Task, TaskStatus } from '../types';

const CardView = ({
  tasks,
  addTask,
}: {
  tasks: Task[];
  addTask: React.Dispatch<React.SetStateAction<Task[]>>;
}) => {
  const initialTaskState: Task = {
    id: '',
    title: '',
    description: '',
    status: TaskStatus.OPEN,
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  const [newTask, setNewTask] = React.useState<Task>(initialTaskState);
  const [addTaskMode, setAddTaskMode] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  console.log(error);

  return (
    <Box
      sx={{
        marginBottom: 2,
      }}
    >
      <Stack spacing={2}>
        {tasks.map(task => (
          <Card key={task.id}>
            <CardHeader
              title={task.title}
              titleTypographyProps={{
                variant: 'body1',
              }}
              subheader={task.description}
              subheaderTypographyProps={{
                variant: 'body2',
              }}
              action={
                <IconButton aria-label='settings'>
                  <MoreVertIcon />
                </IconButton>
              }
            />
          </Card>
        ))}
        {!addTaskMode ? (
          <Button
            variant='outlined'
            onClick={() => setAddTaskMode(true)}
            startIcon={<AddIcon />}
          >
            Add Task
          </Button>
        ) : (
          <>
            <Card
              sx={{
                border: error ? '2px solid' : '1px solid',
                borderColor: error ? 'error.dark' : 'grey.500',
              }}
              component='form'
              onSubmit={e => {
                // Basic validation
                if (newTask.title === '') {
                  setError('title is required');
                  return;
                }

                addTask([...tasks, newTask]);
                setNewTask(initialTaskState);
                setAddTaskMode(false);
              }}
            >
              <CardHeader
                title={
                  <TextField
                    placeholder={'Enter title...'}
                    value={newTask.title}
                    onChange={e =>
                      setNewTask({
                        ...newTask,
                        id: new Date().getTime().toString(),
                        title: e.target.value,
                      })
                    }
                    required
                    sx={{
                      padding: 0,
                      border: 'none',
                      mb: 1,
                    }}
                    variant='standard'
                    fullWidth
                    error={error.includes('title')}
                    autoFocus
                  />
                }
                titleTypographyProps={{
                  variant: 'body1',
                }}
                subheader={
                  <TextField
                    placeholder={'Enter description...'}
                    value={newTask.description}
                    onChange={e =>
                      setNewTask({
                        ...newTask,
                        id: new Date().getTime().toString(),
                        description: e.target.value,
                      })
                    }
                    sx={{
                      padding: 0,
                      border: 'none',
                    }}
                    variant='standard'
                    multiline
                    fullWidth
                    rows={3}
                  />
                }
                subheaderTypographyProps={{
                  variant: 'body2',
                }}
              />
              <CardActions>
                <Button
                  onClick={() => {
                    setAddTaskMode(false);
                    setNewTask(initialTaskState);
                    setError('');
                  }}
                  size='small'
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  size='small'
                >
                  Add
                </Button>
                {error && (
                  <Typography
                    color='error'
                    variant='subtitle2'
                    sx={{ ml: 'auto', pr: 1 }}
                  >
                    {error}
                  </Typography>
                )}
              </CardActions>
            </Card>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default CardView;
