import React from 'react';
import './App.css';

import version from './VERSION.json';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

import CardView from './pages/CardView';
import { Task, TaskStatus } from './types';

function App() {
  const initialData: Task[] = [
    {
      id: '1',
      title: 'Task 1',
      description: 'Description 1',
      status: TaskStatus.OPEN,
      author: 'Author 1',
      assignee: 'Assignee 1',
      createdAt: new Date(),
      updatedAt: new Date(),
    },
  ];
  const [tasks, setTasks] = React.useState(initialData);
  const [view, setView] = React.useState('card');

  return (
    <Box>
      <Paper
        elevation={0}
        component='header'
        square
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
        }}
      >
        <Typography
          sx={{
            px: 2,
            py: 1,
          }}
          variant='h5'
        >
          Hyperify
        </Typography>
      </Paper>
      <Box component='main' sx={{ p: 2 }}>
        <Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent='flex-end'
            alignItems='center'
            sx={{ mb: 2 }}
          >
            <Typography variant='h6' fontWeight='400' sx={{ mr: 1 }}>
              View:
            </Typography>
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={(event, newValue) => {
                setView(newValue);
              }}
            >
              <ToggleButton value='card' aria-label='card'>
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value='table' aria-label='table'>
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            {view === 'card' && <CardView tasks={tasks} addTask={setTasks} />}
            {view === 'table' && (
              <Table size='small'>
                <TableHead
                  sx={{
                    '& .MuiTableCell-head': {
                      bgcolor: 'grey.400',
                      color: 'grey.900',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Author</TableCell>
                    <TableCell>Assignee</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Updated At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map(task => (
                    <TableRow key={task.id}>
                      <TableCell>{task.title}</TableCell>
                      <TableCell>{task.description}</TableCell>
                      <TableCell>{task.status}</TableCell>

                      <TableCell>{task.author}</TableCell>
                      <TableCell>{task.assignee}</TableCell>
                      <TableCell>{task.createdAt.toLocaleString()}</TableCell>
                      <TableCell>{task.updatedAt.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
        <Typography variant='caption'>Version {version.VERSION}</Typography>
      </Box>
    </Box>
  );
}

export default App;
